import api from '@/plugins/axios'

const base = 'v1/'

export default {
  checkCreatedPassword (userId, req) {
    return api.get(`${base}${req}/senha-ja-criada?userId=${userId}`)
  },
  confirmAccount (data, req) {
    return api.post(`${base}${req}/confirmar-conta`, data)
  },
  confirmAccountCreatePassword (data, req) {
    return api.post(`${base}${req}/confirmar-conta-e-criar-senha`, data)
  },
  requestConfirmEmailAgain (req) {
    return api.post(`${base}${req}/solicitar-email-confirmacao-conta`)
  }
}
