<template>
  <div class="container min-h-screen flex flex-col items-center justify-center">
    <div v-if="loadingPage" class="content">
      <p class="title title_loading">Aguarde, carregando...</p>
      <i class="fas fa-spinner fa-spin title_loading text-xl"></i>
    </div>
    <div v-else-if="valid" class="content">
      <div v-if="createdPassword" class="content">
        <p class="title title_success">Conta confirmada!</p>
        <p class="subtitle">Redirecionando para seu painel em breve...</p>
        <p class="text">Se não funcionar, <router-link class="link" :to="$route.query.cnpj ? '/socio/aceitar-condicoes' : `/${req}/painel`">clique aqui</router-link></p>
      </div>
      <div v-else class="lg:w-1/2">
        <p class="title title_success">Crie sua senha!</p>
        <v-input name="senha" type="password" v-model="form.senha" :error="errors.first('senha')" label="Senha" v-validate="'required|min:6'"></v-input>
        <v-input name="confirmacaoSenha" type="password" v-model="form.confirmacaoSenha" :error="errors.first('confirmacaoSenha')" label="Repetir senha" v-validate="{required: true, min: 6, is: form.senha}"></v-input>
        <v-button class="flex justify-center" @click="submit" :loading="loading">Criar senha</v-button>
      </div>
    </div>
    <div v-else class="content">
      <p class="title title_error">Link inválido</p>
      <p class="subtitle">Redirecionando para a página principal em breve...</p>
      <p class="text">Se não funcionar, <router-link class="link" to="/">clique aqui</router-link></p>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'

import ConfirmAccount from '@/services/Commons/ConfirmAccount'
import { setTimeout } from 'timers'

export default {
  props: ['req'],
  components: { VButton, VInput },
  data () {
    return {
      valid: false,
      loadingPage: true,
      loading: false,
      createdPassword: false,
      error: null,

      userId: null,
      token: null,
      solicitacaoId: null,
      cnpj: null,

      form: {
        senha: '',
        confirmacaoSenha: ''
      }
    }
  },
  methods: {
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            let response
            if (this.cnpj) {
              response = (await ConfirmAccount.confirmAccountCreatePassword({ ...this.form, token: this.token, userId: this.userId, cnpj: this.cnpj }, this.req)).data.data
            } else {
              response = (await ConfirmAccount.confirmAccountCreatePassword({ ...this.form, token: this.token, userId: this.userId }, this.req)).data.data
            }
            this.createdPassword = true
            this.$store.dispatch('setLogout')
            this.$store.dispatch('setLogin', response)


          console.log(response)

            if (this.cnpj) {
               if (response.roles.includes('socio_tomador')) {
                console.log('tem role');
                this.$router.push('/socio/aceitar-condicoes')
              } else{
                console.log('nao tem role');
              }
              //this.$store.dispatch('setLogout')
              //this.$router.push({ path: '/tomador/login', query: { isPartner: true } })
            } else {
              console.log('nao tem cnpj');
              setTimeout(() => this.$router.push(`/${this.req}/painel${this.solicitacaoId ? `/solicitacao/${this.solicitacaoId}` : ''}`), 5000)
            }
          } catch (error) {
            this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response.data.erros.password) {
              this.errors.add({ field: 'senha', msg: error.response.data.erros.password.split(':')[1].slice(0, -2) })
            } else if (error.response.data.erros.token) {
              this.valid = false
              setTimeout(() => this.$router.push('/'), 5000)
            } else {
              console.error(error.response.data.erros)
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  async mounted () {
    const { userId, token, solicitacaoId, cnpj, email } = this.$route.query
console.log(this.$route.query)
    if (!(userId && (token || cnpj) && this.req)) {
      this.valid = false
      this.loadingPage = false
      setTimeout(() => this.$router.push('/'), 5000)
    } else {
      try {
        this.valid = true
        this.userId = userId
        this.token = token
        this.solicitacaoId = solicitacaoId
        this.cnpj = cnpj
        this.createdPassword = (await ConfirmAccount.checkCreatedPassword(userId, this.req)).data.data
        this.loadingPage = false
        if (this.createdPassword) {
          if (this.cnpj) {
            this.$store.dispatch('setLogout')
            this.$router.push({ path: '/tomador/login', query: { isPartner: true, cnpj, email } })
          } else {
            const response = (await ConfirmAccount.confirmAccount({ userId, token }, this.req)).data.data
            this.$store.dispatch('setLogout')
            this.$store.dispatch('setLogin', response)

            setTimeout(() => this.$router.push(`/${this.req}/painel${this.solicitacaoId ? `/solicitacao/${this.solicitacaoId}` : ''}`), 5000)
          }
        }
      } catch (err) {
        this.valid = false
        console.error(err)
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.content
  @apply w-full flex flex-col items-center justify-center border shadow-lg rounded-lg
  min-height: 10rem

.title
  @apply font-bold my-4 text-lg
  @screen lg
    @apply text-xl
  &_error
    @apply text-red-600
  &_loading, &_success
    @apply text-primary

.subtitle
  @apply opacity-75 text-center
  @screen lg
    @apply text-lg

.text
  @apply opacity-75 text-center text-sm
  @screen lg
    @apply text-base

.link
  @apply text-secondary
  &:hover, &:focus
    @apply font-bold cursor-pointer

</style>
